<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-11-11 09:43:10
 * @LastEditTime: 2022-11-18 15:31:19
 * @Description: 图片验证
 * @FilePath: \BusinessPlatform\src\views\login\components\pictureVerification.vue
 -->

<template>
    <div class="pictureVerification">
        <slide-verify
            :w="420"
            :h="240"
            ref="slideblock"
            slider-text="向右拖动滑块填充拼图"
            :accuracy="4"
            :imgs="imgs"
            @again="onAgain"
            @success="onSuccess"
            @fail="onFail"
        ></slide-verify>
    </div>
</template>

<script>
export default {
    name: 'picture-verification',
    components: {},
    data() {
        return {
            imgs: [
                require('./../images/verifyPicture/bg_1.png'),
                require('./../images/verifyPicture/bg_2.png'),
                require('./../images/verifyPicture/bg_3.png'),
                require('./../images/verifyPicture/bg_4.png'),
                require('./../images/verifyPicture/bg_5.png'),
                require('./../images/verifyPicture/bg_6.png'),
            ],
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.resetVerify();
    },
    activated() {},
    computed: {},
    methods: {
        onSuccess() {
            this.$emit('imageVerificationSucceeded');
        },
        onFail() {
            this.$message({
                showClose: true,
                message: '验证失败',
                type: 'error',
            });
        },
        onAgain() {
            this.$message({
                showClose: true,
                message: '验证失败',
                type: 'error',
            });
            this.$refs.slideblock.reset();
        },
        handleClick() {
        // 父组件直接可以调用刷新方法
            this.$refs.slideblock.reset();
        },
        // 重置插件
        resetVerify() {
            if (this.$refs.slideblock.resetVerify) {
                this.$refs.slideblock.resetVerify();
            }
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.pictureVerification
    >>>.slide-verify
        .slide-verify-slider
            .slide-verify-slider-mask-item
                width 38px
                height 38px
            .slide-verify-slider-text
                color #868FA3
</style>
